import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Linkbox } from 'hds-react';
import { withPrefix } from "gatsby";
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/linkbox",
  "title": "Linkbox",
  "navTitle": "Linkbox"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" withBorder mdxType="Linkbox">
    <div style={{
          height: '106px'
        }} />
  </Linkbox>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Linkbox must always be interactable in its entirety.`}</strong>{` This means that Linkboxes must not include multiple interactable elements (e.g. buttons or multiple links). The Linkbox element only gets focused once.`}</li>
      <li parentName="ul">{`To emphasize its interactability, the Linkbox includes a mandatory icon. Depending on the link type the icon is either an arrow or an external link icon. Do not remove or modify these icons.`}
        <ul parentName="li">
          <li parentName="ul">{`To read more about HDS recommendations about links, see `}<InternalLink href="/components/link" mdxType="InternalLink">{`HDS Link documentation`}</InternalLink>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Linkboxes work very well for listing heterogenous content`}</strong>{` such as news items, blog posts, or upcoming events.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use Linkboxes sparingly.`}</strong>{` While they can drastically improve service's understandability and readability, overusing them may result in confusing and complex user interfaces.`}</li>
    </ul>
    <h4 {...{
      "id": "should-i-use-a-linkbox-or-a-card",
      "style": {
        "position": "relative"
      }
    }}>{`Should I use a Linkbox or a Card?`}<a parentName="h4" {...{
        "href": "#should-i-use-a-linkbox-or-a-card",
        "aria-label": "should i use a linkbox or a card permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use the Linkbox component when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`the whole element needs to be interactable`}</li>
          <li parentName="ul">{`the element does not include multiple interactable elements`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Use the `}<InternalLink href="/components/card" mdxType="InternalLink">{`Card component`}</InternalLink>{` when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`the whole Card does not need to be interactable`}</li>
          <li parentName="ul">{`the Card contains multiple interactable elements (e.g. buttons or links)`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "empty",
      "style": {
        "position": "relative"
      }
    }}>{`Empty`}<a parentName="h4" {...{
        "href": "#empty",
        "aria-label": "empty permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The default empty Linkbox variant can be used to build Linkboxes with custom content.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <div style={{
        maxWidth: '320px'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" mdxType="Linkbox">
      <div style={{
            height: '106px'
          }} />
    </Linkbox>
  </div>
  <div style={{
        maxWidth: '320px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" withBorder mdxType="Linkbox">
      <div style={{
            height: '106px'
          }} />
    </Linkbox>
  </div>
  <div style={{
        maxWidth: '320px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" noBackground mdxType="Linkbox">
      <div style={{
            height: '106px'
          }} />
    </Linkbox>
  </div>
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-heading-and-body-text",
      "style": {
        "position": "relative"
      }
    }}>{`With heading and body text`}<a parentName="h4" {...{
        "href": "#with-heading-and-body-text",
        "aria-label": "with heading and body text permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Additionally, HDS offers styling for basic heading and body text inside the Linkbox component. These can be used instead of custom elements.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <div style={{
        maxWidth: '320px'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Linkbox title" text="Linkbox text" mdxType="Linkbox" />
  </div>
  <div style={{
        maxWidth: '320px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Linkbox title" text="Linkbox text" withBorder mdxType="Linkbox" />
  </div>
  <div style={{
        maxWidth: '320px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Linkbox title" text="Linkbox text" noBackground mdxType="Linkbox" />
  </div>
    </PlaygroundPreview>
    <h4 {...{
      "id": "internal-and-external-linkboxes",
      "style": {
        "position": "relative"
      }
    }}>{`Internal and external Linkboxes`}<a parentName="h4" {...{
        "href": "#internal-and-external-linkboxes",
        "aria-label": "internal and external linkboxes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Depending on the link type the icon is either an arrow or an external link icon. Use the property `}<inlineCode parentName="p">{`external`}</inlineCode>{` to alter the icon depending on the link type. You can also add `}<inlineCode parentName="p">{`openInNewTab`}</inlineCode>{` property to make the link open in a new browser tab.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <div style={{
        maxWidth: '320px'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Internal link" text="This link points to a page belonging to the same website as the current page." withBorder mdxType="Linkbox" />
  </div>
  <div style={{
        maxWidth: '320px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="External link" text="This link points to another page." openInExternalDomainAriaLabel="Opens a different website." external withBorder mdxType="Linkbox" />
  </div>
  <div style={{
        maxWidth: '320px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="External link" openInNewTab openInNewTabAriaLabel="Opens in a new tab." text="This link points to another page and opens in a new tab." openInExternalDomainAriaLabel="Opens a different website." external withBorder mdxType="Linkbox" />
  </div>
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-image",
      "style": {
        "position": "relative"
      }
    }}>{`With image`}<a parentName="h4" {...{
        "href": "#with-image",
        "aria-label": "with image permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS offers styling for a Linkbox with an image as its content.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <div style={{
        maxWidth: '384px'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Linkbox title" text="Linkbox text" imgProps={{
          src: withPrefix('/images/foundation/visual-assets/placeholders/image-m@3x.png'),
          width: 384,
          height: 245
        }} mdxType="Linkbox" />
  </div>
    </PlaygroundPreview>
    <h4 {...{
      "id": "linkbox-size-variants",
      "style": {
        "position": "relative"
      }
    }}>{`Linkbox size variants`}<a parentName="h4" {...{
        "href": "#linkbox-size-variants",
        "aria-label": "linkbox size variants permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`HDS Linkbox includes three (3) size variants; small, default, and large.
You can use different sizes depending on the screen size or use case.
Size variants differ in (default) heading size and inner spacing. Use the size property to alter the size.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <div style={{
        maxWidth: '284px'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Linkbox title" text="Linkbox text" size="small" imgProps={{
          src: withPrefix('/images/foundation/visual-assets/placeholders/image-m@3x.png'),
          width: 284,
          height: 181
        }} mdxType="Linkbox" />
  </div>
  <div style={{
        maxWidth: '384px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Linkbox title" text="Linkbox text" size="medium" imgProps={{
          src: withPrefix('/images/foundation/visual-assets/placeholders/image-m@3x.png'),
          width: 384,
          height: 245
        }} mdxType="Linkbox" />
  </div>
  <div style={{
        maxWidth: '567px',
        marginTop: 'var(--spacing-s)'
      }}>
    <Linkbox linkboxAriaLabel="Linkbox: Helsinki Design System" linkAriaLabel="HDS" href="https://hds.hel.fi" heading="Linkbox title" text="Linkbox text" size="large" imgProps={{
          src: withPrefix('/images/foundation/visual-assets/placeholders/image-m@3x.png'),
          width: 567,
          height: 363
        }} mdxType="Linkbox" />
  </div>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      